import React from 'react';
import { object, string } from 'prop-types';

import { Box, Center, Heading, Text } from '@chakra-ui/react';
import Image from '@/components/Common/LazyImage';
import InstagramButton from '@components/Common/InstagramButton';
import WithAnimation from '@components/Common/WithAnimation';

function DetailCouple(props) {
  const {
    imgUrl,
    instagramId,
    parentName,
    fullName,
    shortName,
    shortNameProps,
    instagramIdProps,
    fullNameProps,
    imgUrlProps,
    parentNameProps,
  } = props;

  return (
    <Box>
      <WithAnimation>
        <Center>
          {/* Image ration must 1:1 */}
          <Image
            src={imgUrl}
            maxWidth="200px"
            borderRadius="59% 41% 45% 55% / 51% 54% 46% 49%"
            marginBottom="16px"
            {...imgUrlProps}
          />
        </Center>
      </WithAnimation>
      <WithAnimation>
        <Heading
          color="secondaryColorText"
          textAlign="center"
          fontSize="6xl"
          fontWeight="normal"
          {...shortNameProps}
        >
          {shortName}
        </Heading>
      </WithAnimation>
      <WithAnimation>
        <Text color="secondaryColorText" fontSize="17px" textAlign="center" {...fullNameProps}>
          <span dangerouslySetInnerHTML={{ __html: fullName }} />
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Text
          color="secondaryColorText"
          textAlign="center"
          fontSize="14px"
          dangerouslySetInnerHTML={{ __html: parentName }}
          {...parentNameProps}
        />
      </WithAnimation>
      {instagramId && (
        <Center marginTop="24px">
          <WithAnimation>
            <InstagramButton
              onlyIcon
              id={instagramId}
              backgroundColor="mainColorText"
              {...instagramIdProps}
            />
          </WithAnimation>
        </Center>
      )}
    </Box>
  );
}

DetailCouple.propTypes = {
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  parentName: string.isRequired,
  fullName: string.isRequired,
  shortName: string.isRequired,
  shortNameProps: object,
  instagramIdProps: object,
  fullNameProps: object,
  imgUrlProps: object,
  parentNameProps: object,
};

DetailCouple.defaultProps = {
  shortNameProps: {},
  instagramIdProps: {},
  fullNameProps: {},
  imgUrlProps: {},
  parentNameProps: {},
};

export default DetailCouple;
