import React from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import ImagesViewer from '@components/Common/ImagesViewer/Lazy';
import WithAnimation from '@components/Common/WithAnimation';
import RSVP from '@/components/Section/RSVP';
import * as ID from '@/constants/identifier';

import { BG_SECONDARY } from '@/constants/colors';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { BG_RSVP } from '@/constants/assets';

function CovidSection({...rest}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = useStaticQuery(graphql`
    query {
      covid: file(relativePath: { eq: "covid.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      covidEn: file(relativePath: { eq: "covid-en.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box bgColor="bgPrimary" padding="32px 16px" >
      <Box height="100px" {...rest} />
      <Box
        bgColor="mainColor"
        marginBottom="-40px"
        borderRadius="16px"
        bgImage={`url(${BG_RSVP})`}
        bgSize="cover"
      >
        <Box bgColor="rgb(227 167 75 / 35%)" padding="16px 32px" borderRadius="16px">
          <WithAnimation>
            <Box
              boxShadow="lg"
              borderRadius="40px"
              onClick={onOpen}
              backgroundColor="mainColor"
              border={`6px solid ${BG_SECONDARY}`}
              transform="translateY(-90px)"
            >
              <Img
                fluid={lang === 'en' ? data.covidEn.childImageSharp.fluid : data.covid.childImageSharp.fluid}
                imgStyle={{ borderRadius: '32px' }}
                style={{ backgroundColor: 'transparent' }}
              />
            </Box>
          </WithAnimation>
          <RSVP name={ID.ID_RSVP_SECTION} />
        </Box>
      </Box>
      <Box height="42px" />
      {isOpen && (
        <ImagesViewer 
          images={[
            lang === 'en' 
              ? data.covidEn.childImageSharp.fluid.src
              : data.covid.childImageSharp.fluid.src
          ]}onClose={onClose}
        />
      )}
    </Box>
  );
}

export default React.memo(CovidSection);
