import Img1 from './assets/1.jpg';
import Img2 from './assets/2.jpg';
import Img3 from './assets/3.jpg';
import Img4 from './assets/4.jpg';
import Img5 from './assets/5.jpg';
import Img6 from './assets/6.jpg';
import Img7 from './assets/7.jpg';
import Img8 from './assets/8.jpg';

export default [
  {
    img: Img1,
    // title: '21 Agustus 2023',
    description: `Berawal pada tanggal 21 Agustus 2023, kami mulai berkenalan melalui chat.`,
  },
  {
    img: Img2,
    // title: '26 Agustus 2023',
    description: `Kemudian tanggal 26 Agustus 2023, kami memutuskan untuk berjumpa pertama kalinya di Love Seafood Batam Center dan dari pertemuan itulah muncul rasa ketertarikan diantara kami berdua.`,
  },
  {
    img: Img3,
    // title: 'September 2023',
    description: `Dan setelah pertemuan pertama tersebut, setiap akhir pekan kami sering berjumpa dan semakin dekat.`,
  },
  {
    img: Img4,
    // title: '2021',
    description: `Tepat pada tanggal 17 September 2023, Mas Dio menjumpai kedua orang tua Anti dan mengatakan niatnya untuk mengajak Anti menikah.`,
  },
  {
    img: Img5,
    // title: '2022',
    description: `Kemudian pada tanggal 7 Oktober 2023, Terjadilah pertemuan keluarga untuk pertama kalinya diantara kami, yang dimana Mas Dio dan keluarga datang untuk mengutarakan niat baiknya untuk melamar Anti, dan setelah pertemuan keluarga tersebut, kedua belah pihak keluarga pun sepakat untuk membawa hubungan kami ke jenjang yang lebih serius.`,
  },
  {
    img: Img6,
    // title: '2022',
    description: `Pada tanggal 21 Oktober 2023, kami pun melaksanakan acara seserahan.`,
  },
  {
    img: Img7,
    // title: '2022',
    description: `Dan pada tanggal 18 Januari 2024, kami pun melaksanakan acara adat nias.`,
  },
  {
    img: Img8,
    // title: '2022',
    description: `Tepat pada hari Sabtu 20 Januari 2024 kami pun melangsungkan pernikahan.`,
  },
];