import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';

import { BG_AYAT } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import txtWording from './locales';

/**
 * function to render ayat section with Ayat content
 * @returns {JSX.Element}
 */
function AyatSection() {
  return (
    <Box padding="0 32px 42px 32px " bgColor="mainColor">
      <Center>
        <WithAnimation>
          <Image
            src={BG_AYAT}
            borderTopRadius="48%"
            borderBottomRadius="8px"
            height="350px"
            transform="translateY(40px)"
          />
        </WithAnimation>
      </Center>
      <Box p="24px 32px" bg="white" borderRadius="16px">
        <WithAnimation>
          <Text textAlign="center" fontSize="sm" color="mainColorText" paddingTop="40px">
            {txtWording.ayat[lang]}
            <br />
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text fontSize="sm" align="center" color="mainColorText" marginTop="8px">
            {txtWording.ayatName[lang]}
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(AyatSection);