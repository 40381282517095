import React from 'react';
import { Box, AspectRatio, Heading, Text, Button, Center, Link } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import CountDown from '@components/Common/CountDown';
import Image from '@/components/Common/LazyImage';

import { BG_COUNTING } from '@/constants/assets';
import { THE_BRIDE, YOUTUBE_EMBED, YOUTUBE_LINK } from '@/constants';
import {
  ENABLE_LIVE_STREAMING,
  DEFAULT_LANGUAGE as lang,
  ENABLE_VIDEO_PREWEDDING,
} from '@/constants/feature-flags';

import txtWording from './locales';

function YoutubeLiveSection({ ...rest }) {
  return (
    <Box bgColor="bgSecondary" padding="32px">
      <Box bgColor="white" borderRadius="16px" {...rest}>
        {!ENABLE_LIVE_STREAMING && !ENABLE_VIDEO_PREWEDDING && (
          <Image src={BG_COUNTING} width="100%" borderRadius="16px" />
        )}
        {!ENABLE_LIVE_STREAMING && ENABLE_VIDEO_PREWEDDING && (
          <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg">
            <iframe
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '16px' }}
            />
          </AspectRatio>
        )}
        {ENABLE_LIVE_STREAMING && (
          <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg">
            <iframe
              title={`Live wedding of ${THE_BRIDE}`}
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '16px' }}
            />
          </AspectRatio>
        )}
        <Box padding="42px 16px 24px 16px">
          <WithAnimation>
            <Heading fontWeight="normal" textAlign="center" fontSize="6xl" color="mainColorText">
              {ENABLE_LIVE_STREAMING && (
                <span dangerouslySetInnerHTML={{ __html: txtWording.title[lang] }} />
              )}
              {!ENABLE_LIVE_STREAMING && (
                <span dangerouslySetInnerHTML={{ __html: txtWording.countingTitle[lang] }} />
              )}
            </Heading>
          </WithAnimation>
          <CountDown lang={[lang]} />
        </Box>
      </Box>
      {ENABLE_LIVE_STREAMING && (
        <Text textAlign="center" color="secondaryColorText" margin="32px 0 16px 0">
          <span dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }} />
        </Text>
      )}
      {ENABLE_LIVE_STREAMING && (
        <Center>
          <Link href={YOUTUBE_LINK} target="_blank">
            <Button
              size="sm"
              colorScheme="blackAlpha"
              variant="solid"
              className="animation-heart"
              color="secondaryColorText"
              bgColor="mainColorText"
              marginBottom="16px"
            >
              {txtWording.open[lang]}
            </Button>
          </Link>
        </Center>
      )}
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);
