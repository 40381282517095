import React from 'react';
import { bool, func } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';

import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Image,
} from '@chakra-ui/react';

import { Timeline, TimelineEvent } from 'react-event-timeline';
import { AiOutlineHeart } from 'react-icons/ai';
import { GiPeaceDove } from 'react-icons/gi';

import loveStoryData from '@/api/love-story';
import ImgLogo from '@/assets/icons/logo.png';
import useInvitation from '@hooks/useInvitation';

import { BG_LOVESTORY } from '@/constants/assets';
import { TEXT_SECONDARY } from '@/constants/colors';
import { ENABLE_MONOGRAM_LOGO, DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import txtWording from './locales';

function LoveStoryContent({ isOpen, onClose }) {
  const invitation = useInvitation();
  const renderIcon = (img) => {
    if (img) {
      return (
        <Image
          src={img}
          boxSize="50px"
          borderRadius="full"
          objectFit="cover"
          objectPosition="center"
        />
      );
    }

    return <AiOutlineHeart color="white" size="18px" />;
  };

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" />
        <DrawerBody bgImage={`url(${BG_LOVESTORY})`} bgSize="cover" bgPos="center">
          <Box paddingTop="32px">
            <Box bgColor="theme.bgSecondary" borderRadius="16px" padding="16px">
              <Center>
                {ENABLE_MONOGRAM_LOGO && <Image src={ImgLogo} maxW="80px" />}
                {!ENABLE_MONOGRAM_LOGO && (
                  <GiPeaceDove
                    color={TEXT_SECONDARY}
                    size="70px"
                    style={{ margin: '8px 0 12px' }}
                  />
                )}
              </Center>
              <Timeline
                lineColor={TEXT_SECONDARY}
                lineStyle={{ top: '20px', height: 'calc(100% - 60px)', left: '25px' }}
              >
                {loveStoryData.map((item, index) => {
                  return (
                    <TimelineEvent
                      key={`index-${index}`}
                      title={item.title}
                      titleStyle={{
                        color: TEXT_SECONDARY,
                        fontWeight: 'bold',
                        paddingLeft: '20px',
                      }}
                      subtitleStyle={{ color: TEXT_SECONDARY }}
                      cardHeaderStyle={{ color: TEXT_SECONDARY }}
                      icon={renderIcon(item.img)}
                      iconStyle={{ width: '50px', height: '50px' }}
                      bubbleStyle={{
                        width: '50px',
                        height: '50px',
                        border: 'none',
                        backgroundColor: 'rgb(200 103 53)',
                      }}
                      contentStyle={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: '0',
                        color: TEXT_SECONDARY,
                        fontSize: '14px',
                        fontWeight: '400',
                        paddingLeft: '20px',
                        marginTop: '8px',
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.description }} />
                    </TimelineEvent>
                  );
                })}
              </Timeline>
            </Box>
          </Box>
          <Center>
            <Button
              leftIcon={<BiArrowBack />}
              size="sm"
              fontWeight="normal"
              margin="16px 0"
              color="white"
              bgColor="bgSecondary"
              textColor="secondaryColorText"
              colorScheme="blackAlpha"
              onClick={() => onClose()}
            >
              {invitation ? (
                <>{txtWording.invitationBack[lang]}</>
              ) : (
                <>{txtWording.annnouncementBack[lang]}</>
              )}
            </Button>
          </Center>
          <Box height="50px" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

LoveStoryContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default LoveStoryContent;
