import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';
import txt from './locales';

function ModalAddress({ lang, onClose, visible, onCopyText }) {
  const address = 'Perum. Grand Bsi Cluster Semeru Blok B No. 5, Kel. Belian, Kec. Batam Kota, Kota Batam';
  const receiverName = `Dio & Anti`;

  const renderBank = () => {
    return (
      <Box padding="16px 0">
        <Text fontSize="xl" textAlign="center" color="mainColor" marginTop="0">
          {receiverName} <br />
        </Text>
        <Text textAlign="center" color="mainColor" fontSize="sm">
          {address}
        </Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            bgColor="mainColor"
            marginTop="16px"
            textColor="secondaryColorText"
            onClick={() => onCopyText(`${receiverName} - ${address}`, txt.successCopyAddress[lang])}
          >
            {txt.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          fontFamily="heading"
          borderBottomWidth="1px"
          fontSize="3xl"
          fontWeight="normal"
          color="mainDarkText"
        >
          {txt.sendGift[lang]}
        </DrawerHeader>
        <DrawerBody>
          <Text align="center" fontSize="md" margin="16px 0" color="mainDarkText">
            {txt.modalDescAddress[lang]}
          </Text>
          {renderBank()}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalAddress.propTypes = {
  lang: string.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

export default ModalAddress;