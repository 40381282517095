import { useLocation } from '@reach/router';
import { ENABLE_QR_INVITATION } from '../constants/feature-flags';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return flag show/hide QRCode
 * @return {string}
 */
function useShowQR() {
  if (!ENABLE_QR_INVITATION) return false;

  const location = useLocation();
  const code = getQueryValue(location, 'code');

  return Boolean(code);
}

export default useShowQR;
