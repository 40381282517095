import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';
import BANK from '@invitato/helpers/dist/constants/banks';

import Image from '@/components/Common/LazyImage';

import txt from './locales';

function ModalBank({ lang, onClose, visible, onCopyText }) {
  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text textAlign="center" color="mainDarkText" marginTop="0" letterSpacing="2px">
          {bankNumber}
        </Text>
        <Text textAlign="center" color="mainDarkText">
          {userName}
        </Text>
        <Center>
          <Button
            marginTop="8px"
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            bgColor="mainDarkText"
            textColor="secondaryColorText"
            onClick={() => onCopyText(bankNumber)}
          >
            {txt.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          fontFamily="heading"
          borderBottomWidth="1px"
          fontSize="3xl"
          color="mainDarkText"
          fontWeight="normal"
        >
          Bank Transfer
        </DrawerHeader>
        <DrawerBody>
          <Text align="center" fontSize="md" margin="16px 0" color="mainColor">
            {txt.modalDesc[lang]}
          </Text>
          {renderBank(BANK.bri, 'Ardianti Zalukhu', '182801001096504')}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalBank.propTypes = {
  lang: string.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

export default ModalBank;
