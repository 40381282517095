import React from 'react';
import { Box } from '@chakra-ui/react';

import RSVP from '@/components/Section/RSVP';
import * as ID from '@/constants/identifier';

import { BG_RSVP } from '@/constants/assets';

function RSVPNonCovidSection({ ...rest }) {
  return (
    <Box bgColor="bgPrimary" padding="32px 16px" {...rest}>
      <Box
        bgColor="mainColor"
        marginBottom="-40px"
        borderRadius="16px"
        bgImage={`url(${BG_RSVP})`}
        bgSize="cover"
        bgPos="top center"
      >
        <Box bgColor="rgb(24 65 102 / 35%)" padding="16px 32px" borderRadius="16px">
          <RSVP transform="translateY(140px)" name={ID.ID_RSVP_SECTION} />
        </Box>
      </Box>
      <Box height="145px" />
    </Box>
  );
}

export default React.memo(RSVPNonCovidSection);
