import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Text, Heading } from '@chakra-ui/react';
import { GiPeaceDove } from 'react-icons/gi';

import WithAnimation from '@components/Common/WithAnimation';
import WeddingLocation from './Location';
import WeddingDetail from './Detail';

import useShiftTime from '@/hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';

import {
  WEDDING_RESEPSI_DAY,
  WEDDING_AKAD_TIME,
  WEDDING_RESEPSI,
  WEDDING_DATE_DATE,
  WEDDING_LOCATION_AKAD,
} from '@/constants';
import { BG_WEDDING } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { BG_SECONDARY_TRANSPARENT, TEXT_PRIMARY } from '@/constants/colors';

import txtWording from './locales';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSectionV3({ color,...rest }) {
  const time = useShiftTime();
  const invitation = useInvitation();

  return (
    <Box bgColor="white" bgImage={`url(${BG_WEDDING})`} bgSize="cover" bgPosition="center" padding="32px 24px" boxShadow={`inset 0 0 0 2000px ${BG_SECONDARY_TRANSPARENT}`} {...rest}>
      <Box bgColor="bgPrimary" padding="16px" borderRadius="16px">
        <Box boxShadow="2xl" bgColor="white" borderRadius="2xl" padding="36px 16px">
          <WithAnimation>
            <Center>
              <GiPeaceDove color={TEXT_PRIMARY} size="60px" />
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Box>
              <WeddingDetail
                color={color}
                title={txtWording.akad[lang]}
                subTitle={
                  <>
                    {WEDDING_RESEPSI_DAY}
                    <br />
                    {WEDDING_DATE_DATE}
                    <br />
                    {WEDDING_AKAD_TIME}
                  </>
                }
              />
              {invitation && (
                <Text
                  textAlign="center"
                  fontSize="lg"
                  color="mainColorText"
                  dangerouslySetInnerHTML={{ __html: WEDDING_LOCATION_AKAD }}
                />
              )}
              {invitation && (
                <Text textAlign="center" fontSize="sm" align="center" color="mainDarkText">
                  Komplek Ruko Green Land RT 05 RW 02, Kel. Teluk Tering, Kec. Batam Kota, Kota Batam
                </Text>
              )}
            </Box>
          </WithAnimation>
          {invitation && (
            <WithAnimation>
              <WeddingDetail
                color={color}
                title={txtWording.reception[lang]}
                subTitle={
                  <>
                    {WEDDING_RESEPSI_DAY}
                    <br />
                    {WEDDING_RESEPSI}
                    <br />
                    {time}
                  </>
                }
              />
            </WithAnimation>
          )}
          {invitation && (
            <WithAnimation>
              <WeddingLocation color={color} />
            </WithAnimation>
          )}
          {!invitation && false && (
            <WithAnimation>
              <Heading textAlign="center" color="mainColorText" fontWeight="normal">
                Informasi
              </Heading>
              <Text textAlign="center" fontSize="sm" color="mainColor">
                Tanpa mengurangi rasa hormat, acara pernikahan kami memberlakukan protokol kesehatan
                yang ketat, seperti pembatasan jumlah tamu undangan. Dalam rangka mematuhi protokol
                kesehatan tersebut, kami bermaksud mengundang Bapak / Ibu / Saudara / i untuk hadir
                secara virtual dengan menonton siaran langsung pelaksanaan akad nikah kami. Mohon
                doa restu agar kami menjadi keluarga yang sakinah, mawaddah, wa rahmah.{' '}
              </Text>
            </WithAnimation>
          )}
        </Box>
      </Box>
    </Box>
  );
}

WeddingSectionV3.propTypes = {
  color: string,
};

WeddingSectionV3.defaultProps = {
  color: 'white',
};

export default React.memo(WeddingSectionV3);