import React, { useMemo } from 'react';
import { scroller } from 'react-scroll';
import { GiPeaceDove } from 'react-icons/gi';
import {
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Image,
  Text,
  Divider,
  DrawerFooter,
  // hooks
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MdClose, MdMenu } from 'react-icons/md';

import useInvitation from '@/hooks/useInvitation';
import useIsDesktopView from '@hooks/useIsDesktopView';
import useLang from '@/hooks/useLang';
import IMG_LOGO from '@/assets/icons/logo.png';

import { THE_BRIDE } from '@/constants';
import { BG_NAVIGATION, TEXT_PRIMARY_DARK } from '@/constants/colors';
import { ENABLE_MONOGRAM_LOGO } from '@/constants/feature-flags';

import useMenuData from './useMenuData';
import txt from './locales';

function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isDesktopView } = useIsDesktopView();
  const toast = useToast();
  const lang = useLang();
  const menuData = useMenuData();

  const btnRef = React.useRef();
  const isInvitation = useInvitation();

  const filteredMenuData = useMemo(
    () =>
      menuData.filter((i) => {
        if (isInvitation) return true;
        if (!i.invitationOnly) return true;
        return false;
      }),
    [],
  );

  const onClickMenu = (ELEMENT_CONTENT_ID, offset) => {
    try {
      onClose();
      // const element = document.getElementById(ELEMENT_CONTENT_ID).offsetTop;
      scroller.scrollTo(ELEMENT_CONTENT_ID, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset,
      });
    } catch {
      toast({
        title: 'Oops!',
        description: txt.menuNotFound[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <IconButton
        colorScheme="blackAlpha"
        ref={btnRef}
        borderRadius="50%"
        position="fixed"
        zIndex="999"
        bottom="12px"
        left="12px"
        size="sm"
        boxShadow="xl"
        bgColor={BG_NAVIGATION}
        onClick={isOpen ? onClose : onOpen}
        icon={isOpen ? <MdClose color="white" /> : <MdMenu color="white" />}
      />
      <Drawer size="xs" placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          maxWidth={isDesktopView ? '500px' : '75%'}
          bgColor="bgPrimary"
          padding="24px 16px"
        >
          <DrawerBody textAlign="right">
            {ENABLE_MONOGRAM_LOGO && (
              <Image src={IMG_LOGO} width="100px" position="absolute" right="32px" />
            )}
            {!ENABLE_MONOGRAM_LOGO && (
              <Box position="absolute" right="32px">
                <GiPeaceDove color={TEXT_PRIMARY_DARK} size="60px" />
              </Box>
            )}
            <Box marginTop={ENABLE_MONOGRAM_LOGO ? '140px' : '100px'}>
              {filteredMenuData.map((i) => {
                return (
                  <div key={i.value}>
                    <Text
                      marginTop="16px"
                      textTransform="uppercase"
                      color="bgSecondary"
                      letterSpacing="3px"
                      cursor="pointer"
                      onClick={() => onClickMenu(i.value, i.offset)}
                    >
                      {i.name}
                    </Text>
                    <Divider width="100%" marginTop="8px" borderColor="blackAlpha.500" />
                  </div>
                );
              })}
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Box textAlign="center" fontSize="xs">
              <Text color="bgSecondary">Created with Love by Invitato</Text>
              <Text color="bgSecondary">{`${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navigation;
