import React from 'react';
import { Box } from '@chakra-ui/react';

import Wave from '@components/Common/Wave';
import Wishlist from './Container';

import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';
import { BG_WISHLIST } from '@/constants/assets';

function WishlistWrapper() {
  return (
    <Box
      background={`url(${BG_WISHLIST})`}
      bgSize="cover"
      bgPosition="center"
      boxShadow={`inset 0 0 0 2000px ${BG_SECONDARY_TRANSPARENT}`}
      paddingTop="60px"
      pos="relative"
    >
      <Box bgColor="transparent" marginTop="2">
        <Wave isWaveWishesHeader bgColor="transparent" pos="absolute" top="-30px" />
        <Wishlist inverse={false} />
        <Wave isWaveWishesFooter bgColor="transparent" />
      </Box>
    </Box>
  );
}

export default WishlistWrapper;
