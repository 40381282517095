import get from '@invitato/helpers/dist/getLocale';

export default {
  textButton: get('Kisah Cinta Kami', 'Our Love Story'),
  desc: get(
    `Baca dan ikuti perjalanan cinta <br />kami sebagai pasangan di sini:`,
    `Read and follow our love of life <br />journey as a couple here:`,
  ),
  invitationBack: get('Kembali ke Undangan', 'Back to Invitation'),
  annnouncementBack: get('Kembali', 'Back'),
};