import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { GiPeaceDove } from 'react-icons/gi';
import { Box, Heading, Center, Text } from '@chakra-ui/react';

import ScrollToDown from '@/components/Common/ScrollToDown/V1';
import WithAnimation from '@/components/Common/WithAnimation';
import CopyRight from '@/components/Section/CopyRight';
import Image from '@/components/Common/LazyImage';

import ImgJawa from '@/assets/icons/logo.png';
import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import useInvitation from '@/hooks/useInvitation';

import { BG_SECONDARY } from '@/constants/colors';
import { BOY_NAME_SHORT, GIRL_NAME_SHORT, IS_BOY_FIRST } from '@/constants';
import { ENABLE_MONOGRAM_LOGO, DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import { styCoverV6 } from './styles';
import txtWording from './locales';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function CoverV3({ onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const invitation = useInvitation();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setShowTitle(true);
  }, []);

  return (
    <Box minHeight="100vh" backgroundColor={BG_SECONDARY}>
      <div css={styCoverV6}>
        <Box display="block">
          {showTitle && (
            <Box>
              <Center padding="0 0 8px 0" paddingTop="1rem">
                {ENABLE_MONOGRAM_LOGO && (<Image src={ImgJawa} maxWidth="55px" />)}
                {!ENABLE_MONOGRAM_LOGO && (<GiPeaceDove color={BG_SECONDARY} size="60px" />)}
              </Center>
              <WithAnimation>
                <Box>
                  <Text textAlign="center" color="bgSecondary" marginBottom="8px">
                    {invitation
                      ? `THE WEDDING OF`
                      : `WEDDING ANNOUNCEMENT`
                    }
                  </Text>
                </Box>
              </WithAnimation>
              {IS_BOY_FIRST && (
                <>
                  <WithAnimation>
                    <Heading
                      textAlign="center"
                      fontWeight="normal"
                      color="bgSecondary"
                      fontSize="7xl"
                      marginTop="-16px"
                    >
                      {BOY_NAME_SHORT}
                    </Heading>
                  </WithAnimation>
                  <WithAnimation>
                    <Heading
                      fontWeight="normal"
                      textAlign="center"
                      color="bgSecondary"
                      fontSize="6xl"
                      marginTop="-28px"
                      paddingLeft="24px"
                    >
                      {GIRL_NAME_SHORT}
                    </Heading>
                  </WithAnimation>
                </>
              )}
              {!IS_BOY_FIRST && (
                <>
                  <WithAnimation>
                    <Heading
                      textAlign="center"
                      fontWeight="normal"
                      color="white"
                      fontSize="7xl"
                      marginTop="-16px"
                    >
                      {GIRL_NAME_SHORT}
                    </Heading>
                  </WithAnimation>
                  <WithAnimation>
                    <Heading
                      fontWeight="normal"
                      textAlign="center"
                      color="white"
                      fontSize="6xl"
                      marginTop="-28px"
                      paddingLeft="32px"
                    >
                      {BOY_NAME_SHORT}
                    </Heading>
                  </WithAnimation>
                </>
              )}
            </Box>
          )}
          <Box position="absolute" width="100%" bottom="10rem" maxWidth="500px">
            <Center>
              <ScrollToDown
                onClick={handleClickDetail}
                loading={loading}
                text={invitation ? txtWording.textOpen[lang] : txtWording.textOpenAnnouncement[lang]} 
              />
            </Center>
          </Box>
        </Box>
      </div>
      <CopyRight
        withWave
        withSong={false}
        withFlatIcon={false}
        transformWave="translateY(-38px)"
        transformBody="translateY(-40px)"
        fontSize="small"
        padding="16px 16px 8px 16px"
      />
    </Box>
  );
}

CoverV3.propTypes = {
  onSeeDetail: func.isRequired,
};

export default CoverV3;