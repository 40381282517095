import React from 'react';
import { Box, Text, Button, Center, useDisclosure, Image } from '@chakra-ui/react';
import { GiPeaceDove } from 'react-icons/gi';

import WithAnimation from '@/components/Common/WithAnimation';
import ImgLogo from '@/assets/icons/logo.png';

import { COVER_LOVE_STORY } from '@/constants/assets';
import { TEXT_PRIMARY } from '@/constants/colors';
import { ENABLE_MONOGRAM_LOGO, DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import Content from './Content';
import txtWording from './locales';
import { styles } from './styles';

function LoveStory({ ...rest }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="bgSecondary" paddingTop="16px">
      <Box padding="16px 32px" paddingBottom="42px" {...rest}>
        <Box css={styles}>
          <WithAnimation>
            <Image
              src={COVER_LOVE_STORY}
              maxHeight="350px"
              width="500px"
              objectFit="cover"
              objectPosition="bottom"
            />
          </WithAnimation>
        </Box>
        <WithAnimation>
          <Box padding="0 8px">
            <Box bgColor="bgPrimary" padding="16px" borderRadius="0 0 24px 24px" marginTop="-24px">
              <Box height="24px" />
              <Center>
                {ENABLE_MONOGRAM_LOGO && <Image src={ImgLogo} width="70px" />}
                {!ENABLE_MONOGRAM_LOGO && <GiPeaceDove color={TEXT_PRIMARY} size="50px" />}
              </Center>
              <WithAnimation>
                <Text padding="8px" color="mainColorText" textAlign="center">
                  <span dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }} />
                </Text>
              </WithAnimation>
              <WithAnimation>
                <Center margin="16px 0">
                  <Button
                    colorScheme="blackAlpha"
                    bgColor="mainColorText"
                    textColor="secondaryColorText"
                    color="white"
                    fontFamily="body"
                    size="sm"
                    fontWeight="light"
                    onClick={() => onOpen()}
                  >
                    {txtWording.textButton[lang]}
                  </Button>
                </Center>
              </WithAnimation>
            </Box>
          </Box>
        </WithAnimation>
      </Box>

      <Content onClose={onClose} isOpen={isOpen} />
    </Box>
  );
}

export default LoveStory;
