import React, { useState } from 'react';

import { Box, Text, Heading, Center, Button, useToast } from '@chakra-ui/react';
import { GiWallet } from 'react-icons/gi';
import { FaHome, FaCheckCircle } from 'react-icons/fa';

import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import useLang from '@hooks/useLang';
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import ModalBank from './ModalBank';
import ModalAddress from './ModalAddress';
import ModalConfirmation from './ModalConfirmation';

import { BG_GIFTCARD } from '@/constants/assets';
import {
  ENABLE_BANK_TRANSFER,
  ENABLE_GIFT_CONFIRMATION,
  ENABLE_SEND_GIFT,
} from '@/constants/feature-flags';
import txt from './locales';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  confirmation: 'confirmation',
  idle: '',
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();
  const lang = useLang();

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txt.success[lang],
        description: message || txt.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Oops!',
        description: txt.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box>
      <Image src={BG_GIFTCARD} width="100%" {...rest} />
      <Box bgColor="bgPrimary" padding="32px">
        <Box padding="32px" bgColor="white" borderRadius="16px">
          <Center>
            <WithAnimation>
              <Heading size="3xl" color="mainColorText" fontWeight="normal">
                {txt.title[lang]}
              </Heading>
            </WithAnimation>
          </Center>
          <WithAnimation>
            <Text color="mainDarkText" align="center" margin="32px 0">
              {txt.desc[lang]}
            </Text>
          </WithAnimation>
          {ENABLE_BANK_TRANSFER && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  leftIcon={<GiWallet style={{ marginRight: '-4px' }} />}
                  fontWeight="normal"
                  bgColor="mainColor"
                  textColor="secondaryColorText"
                  onClick={() => setModalState(MODAL_STATE.bank)}
                >
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_SEND_GIFT && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  leftIcon={<FaHome style={{ marginRight: '-4px' }} />}
                  fontWeight="normal"
                  bgColor="mainColor"
                  textColor="secondaryColorText"
                  onClick={() => setModalState(MODAL_STATE.address)}
                >
                  {txt.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_GIFT_CONFIRMATION && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  className="animation-heart"
                  colorScheme="blackAlpha"
                  variant="outline"
                  leftIcon={<FaCheckCircle style={{ marginRight: '-4px' }} />}
                  fontWeight="normal"
                  color="mainColor"
                  onClick={() => setModalState(MODAL_STATE.confirmation)}
                >
                  {txt.confirm[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
        </Box>
      </Box>
      <ModalBank
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.bank}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.address}
        onCopyText={onCopyText}
      />
      <ModalConfirmation
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.confirmation}
        onCopyText={onCopyText}
      />
    </Box>
  );
}

export default React.memo(GiftCardSection);
