import React from 'react';
import { bool, string } from 'prop-types';
import { Box, Center, Text, Link, Heading, Divider } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import useInvitation from '@/hooks/useInvitation';
import Image from '@/components/Common/LazyImage';
import ImgLogo from '@/assets/icons/logo.png';

import {
  THE_BRIDE,
  SOUND_BY,
  SOUND_URL,
  URL_IG_INVITATO,
  GIRL_NAME_SHORT,
  BOY_NAME_SHORT,
  IS_BOY_FIRST,
} from '@/constants';
import {
  ENABLE_COLLABORATION,
  ENABLE_MONOGRAM_LOGO,
  SELECTED_VENDOR_COLLABORATION,
  DEFAULT_LANGUAGE as lang,
} from '@/constants/feature-flags';
import { BG_FOOTER, INVITATO_LOGO } from '@/constants/assets';

import txtWording from './locales';

function CopyRightSection() {
  const invitation = useInvitation();

  return (
    <Box>
      <Box bgColor="bgSecondary" padding="32px">
        <WithAnimation>
          <Text align="center" color="secondaryColorText" padding="8px 0">
            {invitation ? (
              <>{txtWording.closingInvitation[lang]}</>
            ) : (
              <>{txtWording.closingAnnouncement[lang]}</>
            )}
          </Text>
        </WithAnimation>
      </Box>
      <Box
        bgColor="mainColor"
        bgImage={`url(${BG_FOOTER})`}
        bgSize="cover"
        bgPosition="bottom center"
        paddingTop="42px"
        minHeight="80vh"
        boxShadow="inset 0 0 0 2000px rgb(0 0 0 /20%)"
      >
        <Box textAlign="center" position="absolute" bottom="0" width="100%" maxWidth="500px">
          <Heading color="white" fontWeight="normal" fontSize="3xl">
            With Love,
          </Heading>
          {ENABLE_MONOGRAM_LOGO && (
            <Center marginBottom="-6px">
              <Image src={ImgLogo} maxWidth="80px" />
            </Center>
          )}
          {!ENABLE_MONOGRAM_LOGO && IS_BOY_FIRST && (
            <>
              <Heading
                textAlign="center"
                fontWeight="normal"
                color="white"
                fontSize="6xl"
                marginTop="-4px"
              >
                {BOY_NAME_SHORT}
              </Heading>
              <Heading
                fontWeight="normal"
                textAlign="center"
                color="white"
                fontSize="6xl"
                margin="-20px 0 -20px"
                paddingLeft="5px"
              >
                {GIRL_NAME_SHORT}
              </Heading>
            </>
          )}
          {!ENABLE_MONOGRAM_LOGO && !IS_BOY_FIRST && (
            <>
              <Heading
                textAlign="center"
                fontWeight="normal"
                color="mainColorText"
                fontSize="5xl"
                marginTop="-12px"
              >
                {GIRL_NAME_SHORT}
              </Heading>
              <Heading
                fontWeight="normal"
                textAlign="center"
                color="mainColorText"
                fontSize="5xl"
                margin="-20px 0 -24px"
                paddingLeft="5px"
              >
                {BOY_NAME_SHORT}
              </Heading>
            </>
          )}
          <Box padding="16px 32px">
            <Center>
              <Link href={URL_IG_INVITATO} target="_blank">
                <Image src={INVITATO_LOGO} maxWidth="130px" />
              </Link>
            </Center>
            <Text color="white" fontFamily="body" fontSize="xs">
              Created with Love by Invitato
            </Text>
            {/* Icon IG & WA Not Used */}
            <Box>
              {ENABLE_COLLABORATION && (
                <Box margin="-4px 0 8px">
                  <Center>
                    <Divider
                      margin="16px 0 16px"
                      maxWidth="30%"
                      height="1px"
                      bgColor="white"
                      color="white"
                      border="none"
                      boxShadow="none"
                      borderBottomWidth="none"
                    />
                  </Center>
                  <Center>
                    <Link
                      href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`}
                      target="_blank"
                    >
                      <Image
                        src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                        {...SELECTED_VENDOR_COLLABORATION.imgProps}
                      />
                    </Link>
                  </Center>
                  <Center>
                    <Text color="secondaryColorText" align="center" fontSize="xs">
                      In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                    </Text>
                  </Center>
                </Box>
              )}
              <Center marginTop="12px">
                <Text textAlign="center" color="white" fontSize="xs">
                  {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
                </Text>
              </Center>
              <Center>
                <Link textAlign="center" href={SOUND_URL} color="white" fontSize="xs" isExternal>
                  {`Song by ${SOUND_BY}`}
                </Link>
              </Center>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CopyRightSection.propTypes = {
  fontSize: string,
  withFlatIcon: bool,
  withSong: bool,
};

CopyRightSection.defaultProps = {
  fontSize: 'sm',
  withFlatIcon: true,
  withSong: true,
};

export default React.memo(CopyRightSection);
