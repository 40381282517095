import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

import useInvitation from '@/hooks/useInvitation';
import useGuestName from '@/hooks/useGuestName';
import ImgLogo from '@/assets/icons/logo.png';

import Image from '@/components/Common/LazyImage';
import WithAnimation from '@components/Common/WithAnimation';

import {
  BOY_NAME,
  BOY_NAME_SHORT,
  BOY_PARENT_NAME,
  GIRL_NAME,
  GIRL_NAME_SHORT,
  GIRL_PARENT_NAME,
  IG_BOY,
  IG_GIRL,
  IS_BOY_FIRST,
} from '@/constants';
import { BG_COUPLE, IMG_GIRL, IMG_MAN } from '@/constants/assets';
import {
  ENABLE_MONOGRAM_LOGO,
  DEFAULT_LANGUAGE as lang,
  MUSLIM_INVITATION,
} from '@/constants/feature-flags';
import ImagesFade from '@components/Common/ImagesFade';
import DetailCouple from './Detail';
import txtWording from './locales';

function CoupleInfo({ ...rest }) {
  const isInvitation = useInvitation();
  const name = useGuestName();

  return (
    <Box>
      {IS_BOY_FIRST && (
        <>
          <Box bg="mainColor">
            <ImagesFade />
            <Box padding="32px 0 32px 0" transform="translateY(-40px)" {...rest}>
              <WithAnimation>
                <Text
                  align="center"
                  fontSize="md"
                  color="secondaryColorText"
                  padding="24px 0 8px 0"
                >
                  {txtWording.dear[lang]}
                  <br />
                  {name || txtWording.guest[lang]}
                </Text>
              </WithAnimation>
              <WithAnimation>
                <Text align="center" fontSize="md" color="secondaryColorText" padding="0 32px 24px 32px">
                  {isInvitation ? (
                    <>
                      {MUSLIM_INVITATION
                        ? txtWording.openingInvitationMuslim[lang]
                        : txtWording.openingInvitationGeneral[lang]}
                    </>
                  ) : (
                    <>
                      {MUSLIM_INVITATION
                        ? txtWording.openingAnnuncementMuslim[lang]
                        : txtWording.openingAnnuncementGeneral[lang]}
                    </>
                  )}
                </Text>
              </WithAnimation>
              <DetailCouple
                shortName={BOY_NAME_SHORT}
                fullName={`Ajun Jaksa Madya <br />Fraditio Perwira Pranantama, S.H., M.H.`}
                parentName={BOY_PARENT_NAME}
                instagramId={IG_BOY}
                imgUrl={IMG_MAN}
              />
            </Box>
          </Box>
          <Box
            backgroundImage={`url(${BG_COUPLE})`}
            backgroundRepeat="no-repeat"
            backgroundPosition="bottom right"
            backgroundSize={ENABLE_MONOGRAM_LOGO ? '80%' : '70%'}
            bgColor="bgPrimary"
            padding="42px 32px"
            marginTop="-20px"
            paddingBottom={ENABLE_MONOGRAM_LOGO ? '100px' : '100px'}
          >
            <DetailCouple
              shortName={GIRL_NAME_SHORT}
              fullName={GIRL_NAME}
              parentName={GIRL_PARENT_NAME}
              instagramId={IG_GIRL}
              imgUrl={IMG_GIRL}
              shortNameProps={{ color: 'mainColorText' }}
              fullNameProps={{ color: 'mainColorText' }}
              parentNameProps={{ color: 'mainColorText' }}
              instagramIdProps={{ backgroundColor: 'mainColorText' }}
            />
            {ENABLE_MONOGRAM_LOGO && false && (
              <Box>
                <Text
                  textAlign="center"
                  fontSize="2xl"
                  color="mainColorText"
                  margin="50px 0 16px 0"
                >
                  AND i found the LASt
                </Text>
                <Center>
                  <Image src={ImgLogo} maxW="70px" margin="24px" marginBottom="90px" />
                </Center>
              </Box>
            )}
          </Box>
        </>
      )}
      {!IS_BOY_FIRST && (
        <>
          <Box bg="mainColor">
            <ImagesFade />
            <Box padding="32px" transform="translateY(-40px)" {...rest}>
              <WithAnimation>
                <Text
                  align="center"
                  fontSize="md"
                  color="secondaryColorText"
                  padding="24px 0 8px 0"
                >
                  {txtWording.dear[lang]}
                  <br />
                  {name || txtWording.guest[lang]}
                </Text>
              </WithAnimation>
              <WithAnimation>
                <Text align="center" fontSize="md" color="secondaryColorText" padding="0 0 24px 0">
                  {isInvitation ? (
                    <>
                      {MUSLIM_INVITATION
                        ? txtWording.openingInvitationMuslim[lang]
                        : txtWording.openingInvitationGeneral[lang]}
                    </>
                  ) : (
                    <>
                      {MUSLIM_INVITATION
                        ? txtWording.openingAnnuncementMuslim[lang]
                        : txtWording.openingAnnuncementGeneral[lang]}
                    </>
                  )}
                </Text>
              </WithAnimation>
              <DetailCouple
                shortName={GIRL_NAME_SHORT}
                fullName={GIRL_NAME}
                parentName={GIRL_PARENT_NAME}
                instagramId={IG_GIRL}
                imgUrl={IMG_GIRL}
              />
            </Box>
          </Box>
          <Box
            backgroundImage={`url(${BG_COUPLE})`}
            backgroundRepeat="no-repeat"
            backgroundPosition="bottom right"
            backgroundSize={ENABLE_MONOGRAM_LOGO ? '80%' : '70%'}
            bgColor="bgPrimary"
            padding="42px 32px"
            marginTop="-20px"
            paddingBottom={ENABLE_MONOGRAM_LOGO ? '100px' : '100px'}
          >
            <DetailCouple
              shortName={BOY_NAME_SHORT}
              fullName={BOY_NAME}
              parentName={BOY_PARENT_NAME}
              instagramId={IG_BOY}
              imgUrl={IMG_MAN}
              shortNameProps={{ color: 'mainColorText' }}
              fullNameProps={{ color: 'mainColorText' }}
              parentNameProps={{ color: 'mainColorText' }}
              instagramIdProps={{ backgroundColor: 'bgSecondary' }}
            />
            {ENABLE_MONOGRAM_LOGO && false && (
              <Box>
                <Text
                  textAlign="center"
                  fontSize="2xl"
                  color="mainColorText"
                  margin="50px 0 16px 0"
                >
                  AND i found the LASt
                </Text>
                <Center>
                  <Image src={ImgLogo} maxW="70px" margin="24px" marginBottom="90px" />
                </Center>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default React.memo(CoupleInfo);
