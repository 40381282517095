import React from 'react';
import { string, oneOfType, object } from 'prop-types';
import { Box, Text, Heading } from '@chakra-ui/react';

function WeddingDetail({ title, titleProps, subTitle }) {
  return (
    <Box m="4" marginBottom="24px">
      <Heading textAlign="center" fontSize="5xl" fontWeight="normal" color="mainColorText" {...titleProps}>
        <span dangerouslySetInnerHTML={{ __html: title }}/>
      </Heading>
      <Text fontSize="md" color="mainDarkText" textAlign="center">
        {subTitle}
      </Text>
    </Box>
  );
}

WeddingDetail.propTypes = {
  imgSrc: string,
  title: string.isRequired,
  titleProps: object,
  subTitle: oneOfType([string, object]),
};

WeddingDetail.defaultProps = {
  imgSrc: '',
  titleProps: {},
};

export default React.memo(WeddingDetail);
