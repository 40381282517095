import { css } from '@emotion/react';

import { BG_COVER } from '@/constants/assets';

export const styCoverV6 = css`
  min-height: 89vh;
  width: 100%;
  background-size: cover;
  background-image: url(${BG_COVER});
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
