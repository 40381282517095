import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { FaCalendar, FaMapPin } from 'react-icons/fa';
import { Box, Center, Text, Button, Stack } from '@chakra-ui/react';

import {
  EPOCH_END_EVENT,
  EPOCH_RESEPSI_START,
  GOOGLE_MAPS_ADDRESS,
  GOOGLE_MAPS_LINK,
  THE_BRIDE,
  WEDDING_LOCATION_ROAD,
  WEDDING_LOCATION,
} from '@/constants';
import { TEXT_PRIMARY } from '@/constants/colors';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import useInvitation from '@/hooks/useInvitation';
import txtWording from './locales';

/**
 * extend UTC Time format
 */
dayjs.extend(utc);

function WeddingLocation() {
  const invitation = useInvitation();

  const handleClickGoogleMaps = () => {
    window.open(GOOGLE_MAPS_LINK, '_blank');
  };

  /**
   * Generate link for Google Calendar
   * @returns {void}
   */
  const handleClickAddToCalendar = () => {
    const title = `Wedding of ${THE_BRIDE} by Invitato.net`;
    const finalURL = `text=${encodeURIComponent(title)}`;
    const location = `location=${GOOGLE_MAPS_ADDRESS}`;

    const startDate = dayjs(EPOCH_RESEPSI_START * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');
    const endDate = dayjs(EPOCH_END_EVENT * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');

    window.open(
      `https://www.google.com/calendar/render?action=TEMPLATE&${finalURL}&dates=${startDate}/${endDate}&${location}`,
      '_blank',
    );
  };

  return (
    <Box>
      <Text
        textAlign="center"
        fontSize="lg"
        color={TEXT_PRIMARY}
        dangerouslySetInnerHTML={{ __html: WEDDING_LOCATION }}
      />
      <Text textAlign="center" fontSize="sm" align="center" color="mainDarkText">
        {WEDDING_LOCATION_ROAD}
      </Text>
      {invitation && (
        <Center m="5">
          <Stack direction="row" spacing={4} align="center">
            <Button
              leftIcon={<FaCalendar />}
              size="xs"
              onClick={handleClickAddToCalendar}
              colorScheme="orange"
              className="animation-heart"
              backgroundColor="mainColorText"
              textColor="secondaryColorText"
            >
              {txtWording.reminder[lang]}
            </Button>
            <Button
              leftIcon={<FaMapPin />}
              size="xs"
              onClick={handleClickGoogleMaps}
              colorScheme="orange"
              className="animation-heart"
              backgroundColor="mainColorText"
              textColor="secondaryColorText"
            >
              {txtWording.location[lang]}
            </Button>
          </Stack>
        </Center>
      )}
    </Box>
  );
}

export default WeddingLocation;
