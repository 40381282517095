import React from 'react';
import { bool, string } from 'prop-types';
import { Box, Center, Text, Link } from '@chakra-ui/react';
import { TiHeart } from 'react-icons/ti';

import Wave from '@components/Common/Wave';

import { THE_BRIDE, SOUND_BY, URL_WA_INVITATO, SOUND_URL } from '@/constants';
import { TEXT_SECONDARY } from '@/constants/colors';

function CopyRightSection({
  withSong,
  withFlatIcon,
  transformWave,
  transformBody,
  fontSize,
  withWave,
  ...rest
}) {
  return (
    <Box height="0">
      {withWave && <Wave style={{ backgroundColor: 'transparent', transform: transformWave }} />}
      <Box p={2} bgColor="theme.bgSecondary" transform={transformBody} {...rest}>
        <Center>
          <Text color="secondaryColorText" fontSize={fontSize}>
            Created with
          </Text>
          <TiHeart className="animation-heart" style={{ margin: '0 4px' }} color={TEXT_SECONDARY} />
          <Link fontSize={fontSize} href={URL_WA_INVITATO} color="secondaryColorText" isExternal>
            by Invitato
          </Link>
        </Center>
        <Center>
          <Text color="secondaryColorText" fontSize={fontSize}>
            {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
          </Text>
        </Center>
        {withSong && (
          <Center>
            <Link href={SOUND_URL} color="secondaryColorText" fontSize={fontSize} isExternal>
              {`Song by ${SOUND_BY}`}
            </Link>
          </Center>
        )}
        {withFlatIcon && (
          <Center>
            <Link
              href="https://www.flaticon.com/"
              color="secondaryColorText"
              fontSize={fontSize}
              isExternal
            >
              {`Icon by FlatIcon`}
            </Link>
          </Center>
        )}
        <Center m="1" />
      </Box>
    </Box>
  );
}

CopyRightSection.propTypes = {
  fontSize: string,
  transformBody: string,
  transformWave: string,
  withFlatIcon: bool,
  withSong: bool,
  withWave: bool,
};

CopyRightSection.defaultProps = {
  fontSize: 'sm',
  transformBody: 'translateY(-40px)',
  transformWave: 'translateY(-38px)',
  withFlatIcon: true,
  withSong: true,
  withWave: true,
};

export default React.memo(CopyRightSection);
