import React from 'react';
import { Box } from '@chakra-ui/react';

import Wave from '@/components/Common/Wave';

import {
  IMG_SLIDE_1,
  IMG_SLIDE_2,
  IMG_SLIDE_3,
  IMG_SLIDE_4,
  IMG_SLIDE_5,
} from '@/constants/assets';

import './styles.css';

function ImagesFade() {
  return (
    <Box width="100%" zIndex="1" transform="translateY(200)">
      <div className="slide">
        <Box backgroundImage={`url(${IMG_SLIDE_1})`} />
        <Box backgroundImage={`url(${IMG_SLIDE_2})`} />
        <Box backgroundImage={`url(${IMG_SLIDE_3})`} />
        <Box backgroundImage={`url(${IMG_SLIDE_4})`} />
        <Box backgroundImage={`url(${IMG_SLIDE_5})`} />
      </div>
      <Wave transform="translateY(-38px)" />
    </Box>
  );
}

export default ImagesFade;
